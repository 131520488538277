import React from 'react';
import './header.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faBars} from "@fortawesome/free-solid-svg-icons";

export default props =>
    <nav className="main-header navbar navbar-expand navbar-white navbar-light">
      <ul className="navbar-nav">
        <li className="nav-item">
          <a className="nav-link" data-widget="pushmenu" href="#" role="button">
              {/*<FontAwesomeIcon icon={faBars} />*/}
          </a>
        </li>
      </ul>
      {/*<ul className="navbar-nav ml-auto">*/}
      {/*  <li className="nav-item dropdown">*/}
      {/*    <a className="nav-link" data-toggle="dropdown" href="#">*/}
      {/*      <i className="far fa-comments"></i>*/}
      {/*      <span className="badge badge-danger navbar-badge">3</span>*/}
      {/*    </a>*/}
      {/*  </li>*/}
      {/*</ul>*/}
    </nav>