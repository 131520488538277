import React from "react";
import './NewItemCounter.scss';

export default props => {
    return (
        <>
            <div className="component-new-tem-counter" title="Novos pedidos">
                <span className="badge badge-success">{props.total}</span>
            </div>
        </>)

}