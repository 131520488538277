import React, {useEffect, useState} from "react";
import "./AlertSound.scss";
import {faStopCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";
import sound from '../../sirene.mp3';
import ReactAudioPlayer from 'react-audio-player';
import {useDispatch, useSelector} from "react-redux";
import * as _actions from "../../store/actions-reducers"


export default props => {
    const [ play, setPlay ] = useState(false);
    const requests = useSelector(state => state.requests);
    const alert = useSelector(state => state.alert);
    const dispatch = useDispatch();

    const stopSound = () => {
        dispatch(_actions.alterAlertAction(false));
        dispatch(_actions.salveNewItemsAction([]));

    }

    return (
        <>
            <div className="component-alert-sound">
                { alert ?
                    <>
                        <h3 className={`component-alert-sound__title-sound`}>Clique para pausar o som</h3>
                        <div className="component-alert-sound__mp3">
                            <ReactAudioPlayer
                                src={sound}
                                autoPlay={alert}
                                controls
                                loop={alert}
                                className="component-alert-sound__mp3--none"
                            />
                        </div>

                        <div className={`component-alert-sound__sound-button`}>
                            <Button onClick={(e) => { stopSound()}} size="lg" variant="danger component-alert-sound__sound-button--btn"><FontAwesomeIcon icon={faStopCircle}/></Button>
                        </div>
                    </>
                    : ''}
            </div>
        </>
    )

}
