import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {COLORS} from "../constants/constants";
import * as actions from "../store/actions-reducers";
import useCountItemsColors from "./useCountItemsColors";
import useFilterByBranch from "./useFilterByBranch";

const useFilterByColor = () => {
    let copyRequests = useSelector(state => state.copyRequests);
    let branchFilter = useSelector(state => state.branchFilter);
    const dispatch = useDispatch();
    const { countTotalColors } = useCountItemsColors();
    const { filterByBranch } = useFilterByBranch();

    const setFilter = (color) => {
        let filteredRequests = [];
        filteredRequests = filterByBranch(branchFilter);
        filteredRequests = filteredRequests.filter(request => request.color.toUpperCase() === COLORS[color.toUpperCase()]);
        dispatch(actions.saveRequestsAction(filteredRequests));
        countTotalColors(filteredRequests);
    };

    const filterByColor = (color) => {
        if (color.toUpperCase() === `CLEAR`) {
            let requests = [];
            requests = filterByBranch(branchFilter);
            dispatch(actions.saveRequestsAction(requests));
            countTotalColors(requests);
        } else {
            setFilter(color);
        }
    }

    return {filterByColor};
}

export default useFilterByColor;