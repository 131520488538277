import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';

import Routers from "./routers/Routers";
import Layout from "./components/layout/Layout";

export default () =>
    <React.Fragment>
        <div className="app">
            <Routers/>
        </div>
    </React.Fragment>