import React, {useEffect, useState} from 'react';
import './CaptionBoard.scss'
import {useDispatch, useSelector} from "react-redux";
import useFilterByColor from "../../hooks/useFilterByColor";
import {COLORS} from "../../constants/constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilter} from "@fortawesome/free-solid-svg-icons";
import * as _actions from '../../store/actions-reducers';

export default props => {
    const countRequests = useSelector(state => state.count);
    const requests = useSelector(state => state.requests);
    const colorFilter = useSelector(state => state.colorFilter);
    const dispatch = useDispatch();

    const [countYellow, setCountYellow] = useState(0);
    const [countRed, setCountRed] = useState(0);
    const [countPink, setCountPink] = useState(0);
    const [countGrey, setCountGrey] = useState(0);
    const {filterByColor} = useFilterByColor();
    const [implantation, setImplantation] = useState(0);
    const [others, setOthers] = useState(0);

    const setCount = (countRequests) => {
        setCountYellow(countRequests.yellow);
        setCountRed(countRequests.red);
        setCountPink(countRequests.pink);
        setCountGrey(countRequests.grey);
    }

    const setFilter = color => {
        dispatch(_actions.alterColorFilterAction(color))
        filterByColor(color);
    }

    useEffect(() => {
        const implantation = requests.filter(request => request.type.toUpperCase() === 'IMPLANTAÇÃO').length;
        const others = requests.filter(request => request.type.toUpperCase() !== 'IMPLANTAÇÃO').length;
        setImplantation(implantation);
        setOthers(others);
    }, [requests]);


    useEffect(() => {
        if (countRequests.yellow > 0 || countRequests.red > 0 || countRequests.pink > 0 || countRequests.grey > 0) {
            setCount(countRequests);
        }

    }, [countRequests])

    return (
        <>
            <h4 className="component-caption-board__title">Legenda e total geral</h4>
            <div className="component-caption-board">
                <div
                    className="component-caption-board__yellow"
                >
                    <span className="component-caption-board__icon component-caption-board__yellow--color"></span>
                    <span className="component-caption-board__text">Entregar Hoje - {countYellow}</span>
                </div>
                <div
                    className="component-caption-board__red"
                >
                    <span className="component-caption-board__icon component-caption-board__red--color"></span>
                    <span className="component-caption-board__text">Atrasado - {countRed}</span>
                </div>
                <div
                    className="component-caption-board__pink"
                >
                    <span className="component-caption-board__icon component-caption-board__pink--color"></span>
                    <span className="component-caption-board__text">Atrasado e pedido errado  - {countPink}</span>
                </div>
                <div
                    className="component-caption-board__grey"
                >
                    <span className="component-caption-board__icon component-caption-board__grey--color"></span>
                    <span className="component-caption-board__text">Outros - {countGrey}</span>
                </div>
            </div>
            <div className="component-layout__total">
                <p className="component-caption-board__total_general">
                    <b>TOTAL: <span>{countYellow + countRed + countPink + countGrey}</span></b></p>
            </div>
            <div className="filter__box">
                <div className="filter-box__title">
                    <p>Filtro</p>
                </div>
                <div className="filter-box__filter">
                    <div className="filter__button filter__button--yellow">
                        <button
                            title={"Filtrar pela cor amarela"}
                            className={`filter-button__btn-yellow ${colorFilter === COLORS.YELLOW ? 'filter-button__btn-yellow-active' : ''}`}
                            onClick={() => setFilter(COLORS.YELLOW)}
                        >
                            <FontAwesomeIcon icon={faFilter}/>
                        </button>
                    </div>
                    <div className="filter__button filter__button--red">
                        <button
                            title={"Filtrar pela cor vermelha"}
                            className={`filter-button__btn-red ${colorFilter === COLORS.RED ? 'filter-button__btn-red-active' : ''}`}
                            onClick={() => setFilter(COLORS.RED)}
                        >
                            <FontAwesomeIcon icon={faFilter}/>
                        </button>
                    </div>
                    <div className="filter__button filter__button--pink">
                        <button
                            title={"Filtrar pela cor rosa"}
                            className={`filter-button__btn-pink ${colorFilter === COLORS.PINK ? 'filter-button__btn-pink-active' : ''}`}
                            onClick={() => setFilter(COLORS.PINK)}
                        >
                            <FontAwesomeIcon icon={faFilter}/>
                        </button>
                    </div>
                    <div className="filter__button filter__button--grey">
                        <button
                            title={"Filtrar pela cor cinza"}
                            className="filter-button__btn-grey"
                            className={`filter-button__btn-grey ${colorFilter === COLORS.GREY ? 'filter-button__btn-grey-active' : ''}`}
                            onClick={() => setFilter(COLORS.GREY)}
                        >
                            <FontAwesomeIcon icon={faFilter}/>
                        </button>
                    </div>
                    <div className="filter__button filter__button--clear">
                        <button
                            title={"Filtrar por amarelo"}
                            className="button-filter-clear"
                            onClick={() => setFilter('CLEAR')}
                        >
                            Limpar
                        </button>
                    </div>
                </div>
            </div>

            <div className="type-prescription">
                <span className='type-prescription__implantation'>Implantação: {implantation}</span>
                <span className='type-prescription__others'>Outros: {others}</span>
            </div>


        </>
    );
}
