import instance from "../interceptores";

const handleError = err => {
    console.error(`Api call error in services -> request.js : `, err);
};

export const getRequest = async (url) => {
        const res = await instance.get(url);
        return res.data.result ? res.data.result : res.data;
};

export const getListRequest = async (url) => {
        const res = await instance.get(url);
        return res.data.result ? res.data.result : res.data;
};

export const postRequest = async (url, data = {}) => {
    try {
        const res = await instance({
            url: url,
            method: "POST",
            data
        });

        return res.data;

    } catch (err) {
        new Promise.reject(err)
    }
};

export const putRequest = async (url,data = {}) => {
    try {
        const res = await instance({
            url: url,
            method: "PUT",
            data
        });
        return res.data.result ? res.data.result : res.data;
    } catch (err) {
        handleError(err);
    }
};

export const patchRequest = async (url, data = {}) => {
    try {
        const res = await instance({
            url: url,
            method: "PATCH",
            body: JSON.stringify(data)
        });
        return res.data.result ? res.data.result : res.data;
    } catch (err) {
        new Promise.reject(err)
    }
};

export const deleteRequest = async (url) => {
    try {
        const res = await instance({
            url: url,
            method: "DELETE",
        });
        return res.data.result ? res.data.result : res.data;
    } catch (err) {
        handleError(err);
    }
};

export const Api = {
    deleteRequest,
    getListRequest,
    getRequest,
    postRequest,
    putRequest,
    patchRequest
};
