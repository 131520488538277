import React from "react";
import moment from "moment";

const useDateFormat = () => {
    const dataFormat = (dateRequest) => {

        let date =  dateRequest.split(" ");
        let newDateRequest = date[0].split('/')
        const newDate = newDateRequest[2] + "-" + newDateRequest[1] + "-" + newDateRequest[0];

        return moment(newDate + "T" + date[1]).unix();
    }

    return { dataFormat };
}

export default useDateFormat;