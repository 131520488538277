import { ACTIONS as __ } from "../constants/constants";

const saveRequestsAction = data => ({
    type: __.SEARCH_REQUESTS,
    payload: data
});
const saveCopyRequestsAction = data => ({
    type: __.SEARCH_COPY_REQUESTS,
    payload: data
});
const saveRequestsByColorsAction = data => ({
    type: __.SEARCH_TOTAL_COLORS,
    payload: data
});
const alterLoadingAction = data => ({
    type: __.ALTER_LOADING,
    payload: data
});

const alterColorFilterAction = data => ({
    type: __.ALTER_COLOR_FILTER,
    payload: data
});

const alterBranchFilterAction = data => ({
    type: __.ALTER_BRANCH_FILTER,
    payload: data
});

const alterAlertAction = data => ({
    type: __.ALTER_ALERT,
    payload: data
});
const salveNewItemsAction = data => ({
    type: __.SALVE_NEW_ITEMS,
    payload: data
});

export {
    saveRequestsAction,
    saveRequestsByColorsAction,
    saveCopyRequestsAction,
    alterLoadingAction,
    alterColorFilterAction,
    alterBranchFilterAction,
    alterAlertAction,
    salveNewItemsAction
};