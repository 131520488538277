import React, {useEffect, useState} from 'react';
import './Home.scss'
import Header from "../header/Header";
import Sidebar from "../sidebar/Sidebar";
import Footer from "../footer/Footer";
import CaptionBoard from "../captionBoard/CaptionBoard";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Container} from "react-bootstrap";
import InformationBoard from "../informationBoard/InformationBoard";
import {Api} from "../../services/config/request";
import Load from "../load/Load";
import {storage} from "../../services/config/storage";
import {useDispatch, useSelector} from "react-redux";
import * as _actions from '../../store/actions-reducers';
import useCountItemsColors from "../../hooks/useCountItemsColors";
import useFilterByBranch from "../../hooks/useFilterByBranch";
import AlertSound from "../alertSound/AlertSound";
// import socket from "../socket/Socket";
import moment from "moment";
import useDateFormat from "../../hooks/useDateFormat";
import instance from "../../services/interceptores";
import * as actions from "../../store/actions-reducers";
import { withRouter } from "react-router-dom";


const useInterval = (callback, delay) => {
    const intervalId = React.useRef(null);
    const savedCallback = React.useRef(callback);

    React.useEffect(() => {
        savedCallback.current = callback;
    });

    React.useEffect(() => {

        const tick = () => savedCallback.current();

        if (typeof delay === "number") {
            intervalId.current = window.setInterval(tick, delay);

            return () => window.clearInterval(intervalId.current);
        }
    }, [delay]);

    return intervalId.current;
};


export default props => {
    const [requests, setRequests] = useState([]);
    const {countTotalColors} = useCountItemsColors();
    const {dataFormat} = useDateFormat();
    const branchFilter = useSelector(state => state.branchFilter);
    const copyRequests = useSelector(state => state.copyRequests);
    const newItemsStore = useSelector(state => state.newItems);
    const alert = useSelector(state => state.alert);
    const [isLoading, setIsLoading] = useState(true);

    const {filterByBranch} = useFilterByBranch();

    const dispatch = useDispatch();

    const URL_SERVICE = "iw/farmacia/implantacoesV2";

    window.addEventListener('scroll', event => {
        let height = document.getElementById('caption-board');
        if (window.scrollY >= 0 && window.scrollY <= 189) {
            height.classList.remove('component-home__sidebar-fixed')
        } else {
            height.classList.add('component-home__sidebar-fixed')
        }
    });

    useInterval(() => {
        instance.defaults.headers.Authorization = `Bearer ${storage.fetch.authToken()}`;
        Api.getListRequest(URL_SERVICE).then(response => {
            compare(response);
        });
    }, 120000);

    const compare = (requests) => {
        let newItems  = newItemsStore;
        requests.forEach((request, i) => {
            request.itens.forEach((item, index) => {
                let dateRequest = dataFormat(item.dt_solicitacao);
                let lastDate = storage.fetch.lastDateRequest();
                if (dateRequest > lastDate) {
                    requests[i].totalNewItems += 1;
                    // requests[i].counterToActivateTheSirenForNewItems += 1;
                    requests[i].itens[index].isNew = true;
                    newItems.push(requests[i].itens[index].id);

                } else if (newItems.includes(requests[i].itens[index].id)) {
                    requests[i].totalNewItems += 1;
                    requests[i].itens[index].isNew = true;

                }
            });
        });

        dispatch(_actions.salveNewItemsAction(newItems));

        const isAlert = requests.some((request => request.totalNewItems > 0));
        if ( !alert && isAlert ) {
            dispatch(_actions.alterAlertAction(isAlert));
        }

        dispatch(_actions.saveCopyRequestsAction(requests));
        applyFilterByFilial(requests);
        dispatch(_actions.alterColorFilterAction('CLEAR'));
        storage.set.lastDateRequest(moment(new Date()).unix());
    }

    const applyFilterByFilial = (requests) => {
        if (branchFilter.toUpperCase() !== 'ALL_FILIAL') {
            const filteredByFilial = filterByBranch(branchFilter);
            dispatch(_actions.saveRequestsAction(filteredByFilial));
            setRequests(filteredByFilial);
            countTotalColors(filteredByFilial);
        } else {
            dispatch(actions.saveRequestsAction(requests));
            setRequests(requests);
            countTotalColors(requests);
        }
    }

    useEffect(() => {
        applyFilterByFilial(copyRequests);
    }, [branchFilter]);

    useEffect(() => {
        // socket();
        setIsLoading(true);
        Api.getListRequest(URL_SERVICE).then(response => {
            storage.set.lastDateRequest(moment(new Date()).unix());
            setRequests(response);
            dispatch(_actions.saveRequestsAction(response));
            dispatch(_actions.saveCopyRequestsAction(response));
            countTotalColors(response);
            setIsLoading(false);
        }).catch(error => {
            storage.destroy.authToken();
            return window.location.reload();
        });
    }, []);

    return <>
        <div className="component-home">
            <div className="wrapper">
                <Header/>
                <Sidebar/>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0 text-dark">Painel de acompanhamento de pedidos - Farmácia</h1>
                                </div>
                                <div className="col-sm-6">
                                    {/*<ol className="breadcrumb float-sm-right">*/}
                                    {/*    <li className="breadcrumb-item"><a href="#">Home</a></li>*/}
                                    {/*    <li className="breadcrumb-item active">Dashboard v1</li>*/}
                                    {/*</ol>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="container-fluid">
                        <div className="container-fluid">
                            <div className="row">
                                {isLoading
                                    ?
                                    <Load/>
                                    :
                                    <Container fluid>
                                        <Row>
                                            <Col lg={9}>
                                                <InformationBoard requests={requests}/>
                                            </Col>
                                            <Col lg={3}>
                                                <div id="caption-board">
                                                    <CaptionBoard/>
                                                    <AlertSound/>
                                                </div>
                                            </Col>

                                        </Row>
                                    </Container>
                                }
                            </div>
                        </div>
                    </section>
                </div>
                <Footer/>
            </div>
        </div>

    </>
}




