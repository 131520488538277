import React from "react";
import {useDispatch, useSelector} from "react-redux";
import * as actions from "../store/actions-reducers";

const useFilterByBranch = () => {
    let copyRequests = useSelector(state => state.copyRequests);
    const dispatch = useDispatch();

    const filterByBranch = (branch) => {
        let filteredRequests = [];
        if (branch.toUpperCase() !== 'ALL_FILIAL') {
            filteredRequests = copyRequests.filter(request => request.filial_key.toUpperCase() === branch.toUpperCase());
        } else {
            filteredRequests = copyRequests;
        }
        return filteredRequests;
    }

    return {filterByBranch};
}

export default useFilterByBranch;