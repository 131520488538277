const LS_KEY = {
    auth_token: "token",
    user: "user",
    last_data_request: 'last_date',
};

const set = {
    authToken: data => {
        localStorage.setItem(
            LS_KEY.auth_token,
            JSON.stringify({
                auth_token: data
            })
        );
    },
    user: data => {
        localStorage.setItem(LS_KEY.user, JSON.stringify({user: data}));
    },
    lastDateRequest: data => {
        localStorage.setItem(LS_KEY.last_data_request, parseInt(data));
    }
};

const fetch = {
    authToken: () => {
        const data = localStorage.getItem(LS_KEY.auth_token);
        if (data) {
            try {
                const decoded = JSON.parse(data);
                return decoded.auth_token;
            } catch (err) {
                console.log(err);
            }
        }
    },
    user: () => {
        const data = localStorage.getItem(LS_KEY.user);
        if (data) {
            try {
                const decoded = JSON.parse(data);
                return decoded.user;
            } catch (err) {
                console.log(err);
            }
        }
    },
    lastDateRequest: () => {
        const data = localStorage.getItem(LS_KEY.last_data_request);
        if (data) {
            try {
                return parseInt(data)
            } catch (err) {
                console.log(err);
            }
        }
    }
};

const destroy = {
    authToken: () => {
        localStorage.removeItem(LS_KEY.auth_token);
    },
    user: () => {
        localStorage.removeItem(LS_KEY.user);
    },
    lastDateRequest: () => {
        localStorage.removeItem(LS_KEY.last_data_request)
    }
};

export const storage = {
    set,
    fetch,
    destroy,
};
