import React from 'react';
import './Footer.scss'

export default props =>
    <footer className="main-footer">
      <strong>Copyright &copy; 2014-2019 <a href="http://adminlte.io"> AssisteVida</a>.</strong>
      All rights reserved.
      <div className="float-right d-none d-sm-inline-block">
        <b>Version</b> 1.0.0
      </div>
    </footer>