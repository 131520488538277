import React from "react";
import './ItemCounter.scss';

export default props => {
    return (
        <>
            <div className="component-item-counter">
                <div className="yellow">{props.colors.yellow}</div>
                <div className="red">{props.colors.red}</div>
                <div className="pink">{props.colors.pink}</div>
                <div className="grey">{props.colors.grey}</div>
            </div>
        </>)

}