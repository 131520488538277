import React, {Component, Suspense, lazy} from "react";
import {BrowserRouter, Route, Switch, Redirect} from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import NotFoundPage from "../components/notFoundPage/NotFoundPage";
import HomePage from "../components/home/Home";
import LoginPage from "../components/login/Login";
import isAuthenticated from "./isAuthenticated";
// dynamic import: (for route based code splitting)
// const HomePage = lazy(() => import(/* webpackChunkName: "homepage" */ "views/homePage"));
// const LoginPage = lazy(() => import(/* webpackChunkName: "loginpage" */ "views/loginPage"));

const routes = [
    {
        path: "/home",
        component: HomePage,
        exact: true,
        type: "private"
    },
    {
        path: "/login",
        component: LoginPage,
        type: "public"
    },
    {
        path: "*",
        component: NotFoundPage,
        type: "public"
    }
];

export default () => {
    const routeComponents = routes.map(({path, component, exact, type}, key) => {
        if (type === "public") {
            return exact ? (
                <PublicRoute exact path={path} component={component} key={key}/>
            ) : (
                <PublicRoute path={path} component={component} key={key}/>
            );
        } else {
            return exact ? (
                <PrivateRoute exact path={path} component={component} key={key}/>
            ) : (
                <PrivateRoute path={path} component={component} key={key}/>
            );
        }
    });

    return (
        <div>
            <BrowserRouter>
                <Switch>
                    <Redirect exact from="/" to={isAuthenticated() === true ? "/home" : "/login"}/>
                    {routeComponents}
                </Switch>
            </BrowserRouter>
        </div>
    );

}
