import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { COLORS } from "../constants/constants";
import { saveRequestsByColorsAction } from "../store/actions-reducers";

const useCountItemsColors = () => {

    const dispatch = useDispatch();

    const countTotalColors = requests => {
        let newRequests = [];
        requests.forEach(request => newRequests = [...newRequests, ...request.itens]);

        const totalColors = {
            yellow: newRequests.filter(request => request.color.toUpperCase() === COLORS.YELLOW).length,
            red: newRequests.filter(request => request.color.toUpperCase() === COLORS.RED).length,
            pink: newRequests.filter(request => request.color.toUpperCase() === COLORS.PINK).length,
            grey: newRequests.filter(request => request.color.toUpperCase() === COLORS.GREY).length,
        }
        dispatch(saveRequestsByColorsAction(totalColors))
    }

    return { countTotalColors };
}

export default useCountItemsColors;