import React, {useEffect, useState} from 'react';
import './Sidebar.scss'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser} from "@fortawesome/free-solid-svg-icons";
import {faCircle} from "@fortawesome/free-solid-svg-icons";
import {faHome} from "@fortawesome/free-solid-svg-icons";
import {storage} from "../../services/config/storage";
import {Api} from '../../services/config/request';
import useFilterByBranch from '../../hooks/useFilterByBranch';
import * as _actions from '../../store/actions-reducers'
import {useDispatch} from "react-redux";
import * as actions from "../../store/actions-reducers";

export default props => {
    const [user, setUser] = useState(null);
    const [companies, setCompanies] = useState(null);
    const [allFilialActive, setAllFilialActive] = useState(true);

    const dispatch = useDispatch();

    const logout = () => {
        Api.postRequest('logout').then(() => {
            storage.destroy.authToken();
            storage.destroy.user();
            return window.location.reload();
        }).catch(error => {
            storage.destroy.authToken();
            storage.destroy.user();
            return window.location.reload();
        });
    };

    const setActiveMenu = (index, branch) => {
        if (branch === 'ALL_FILIAL') {
            companies.map(company => company.active = false, []);
            setAllFilialActive(true)
            return false;
        }
        setAllFilialActive(false)
        companies.map((company, i) => company.active = i === index, []);

    }

    const setFilter = (index, branch) => {
        dispatch(actions.alterBranchFilterAction(branch.toUpperCase()));
        dispatch(_actions.alterColorFilterAction('CLEAR'));
        setActiveMenu(index, branch);
    }

    useEffect(() => {
        Api.getRequest('iw/userInfo').then((response) => {
            storage.set.user(response)
            const companies = response.companies.map((filial, index) => {
                return {
                    filial: filial.filial.replace('Vida ', ''),
                    filial_key: filial.filial_key,
                    active: false
                }
            }, [])
            setUser(response);
            setCompanies(companies);


        });
    }, []);


    return (
        <aside className="main-sidebar sidebar-dark-primary elevation-4 component-sidebar">

            <a href="index3.html" className="brand-link">
                <span className="brand-text font-weight-light">Assiste Vida</span>
            </a>
            <div className="sidebar">
                <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div className="image image__avatar">
                        <FontAwesomeIcon icon={faUser}/>
                    </div>
                    <div className="info">
                        <a href="#" className="d-block">{user ? user.name : ''}</a>
                        <button className="info-button" onClick={() => logout()} type="button btn">sair</button>
                    </div>
                </div>

                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar nav-child-indent flex-column" data-widget="treeview" role="menu"
                        data-accordion="false">
                        <li onClick={() => setFilter(null, 'ALL_FILIAL')} className="nav-item nav-item-setting">
                            <a className={allFilialActive ? "nav-link active" : "nav-link"}>
                                <FontAwesomeIcon className="nav-icon" icon={faHome} />
                                <p>Mostrar todas unidades </p>
                            </a>
                        </li>
                        {companies ? companies.map((company, index) => {
                            return (
                                <li className="nav-item nav-item-setting"
                                    key={company.filial_key}

                                    onClick={() => setFilter(index, company.filial_key)}
                                >
                                    <a className={company.active  ? "nav-link active" : "nav-link"}>
                                        <FontAwesomeIcon className="nav-icon" icon={faCircle} />
                                        <p>{company.filial}
                                            {/*<span className="right badge badge-danger">New</span>*/}
                                        </p>
                                    </a>
                                </li>
                            );
                        }) : ''}

                        {/*<li className="nav-item">*/}
                        {/*  <a href="./index3.html" className="nav-link">*/}
                        {/*    <i className="far fa-circle nav-icon"></i>*/}
                        {/*    <p>Itabuna*/}
                        {/*      <span className="badge badge-info right">6</span>*/}
                        {/*    </p>*/}
                        {/*  </a>*/}
                        {/*</li>*/}
                    </ul>
                </nav>
            </div>
        </aside>
    );
}
