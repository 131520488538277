import React from "react";
import { COLORS } from "../constants/constants";

const useCountItemsColorsByIdPrescription = () => {

    const countTotalColorsByIdPrescription = Items => {
       return {
           yellow: Items.filter(request => request.color.toUpperCase() === COLORS.YELLOW).length,
           red: Items.filter(request => request.color.toUpperCase() === COLORS.RED).length,
           pink: Items.filter(request => request.color.toUpperCase() === COLORS.PINK).length,
           grey: Items.filter(request => request.color.toUpperCase() === COLORS.GREY).length,
       }
    }

    return { countTotalColorsByIdPrescription };
}

export default useCountItemsColorsByIdPrescription;