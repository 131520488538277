import React, {useState} from 'react';
import './Login.scss'
import logo from '../../assiste-vida.png'
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import { Api } from '../../services/config/request';
import {storage} from "../../services/config/storage";
import Toast from 'react-bootstrap/Toast'

export default (props) => {

    const [user, setUser] = useState({email: "", password: ""});
    const [show, setShow] = useState(false);
    const toggleShowA = () => setShow(!show);

    const login = (user) => {
        Api.postRequest('login', user).then(response => {
            storage.set.authToken(response.access_token)
            window.location.reload();
        }).catch(error => {
            toggleShowA()
        });
    }

    return (
        <React.Fragment>
            <div className="login-toast">
                <Toast
                    onClose={toggleShowA}
                    show={show}
                    delay={3000}
                    autohide
                    animation={true}

                >
                    <Toast.Header>
                        <strong className="mr-auto">Error ao tentar logar</strong>
                    </Toast.Header>
                    <Toast.Body>Usuario e senha está incorreto!</Toast.Body>
                </Toast>
            </div>
            <div className="login">
                <div className="login__logo">
                    <span className="login__logo-span">
                        <img src={logo} alt="Assiste vida" className="login_logo--img"/>
                    </span>
                </div>
                <div className="login__form">
                    <Form>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>E-mail:</Form.Label>
                            <Form.Control
                                size="lg"
                                className="square-field"
                                type="email"
                                placeholder="Enter email"
                                value={user.email}
                                onChange={event => setUser({...user, email: event.target.value})}
                            />
                        </Form.Group>
                        <Form.Group controlId="formBasicPassword">
                            <Form.Label>Senha:</Form.Label>
                            <Form.Control
                                size="lg"
                                className="square-field"
                                type="password"
                                placeholder="Password"
                                value={user.password}
                                onChange={event => setUser({...user, password: event.target.value})}
                            />
                        </Form.Group>
                        <Button
                            size="lg"
                            variant="primary button__assist_default"
                            type="button"
                            block
                            onClick={() => login(user)}
                            disabled={user.email === "" || user.password === ""}
                        >
                            Entrar
                        </Button>
                    </Form>
                </div>
            </div>
        </React.Fragment>
    );

}
