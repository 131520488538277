import axios from 'axios';
import {API_URL} from "./config/apiUrl";
import {storage} from "./config/storage";

const config = {
    baseURL: API_URL,
    headers: {
        Authorization: `Bearer ${storage.fetch.authToken() ? storage.fetch.authToken() : ""}`,
        "Content-Type": "application/json"
    },
    responseType: 'json'
};

const instance = axios.create(config);

instance.interceptors.request.use(config => {
    return config
}, error => {
    return Promise.reject(error);
});

instance.interceptors.response.use(response => {
    return response;
}, error => {

    return new Promise((resolve, reject) => {
        const originalReq = error.config;

        if (error.response.status === 401 && error.config && !error.config.__isRetryRequest && storage.fetch.authToken()) {
            originalReq._retry = true;
            let res = fetch(`${API_URL + "refresh"}`, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${storage.fetch.authToken()}`
                },
                responseType: 'json',
                redirect: 'follow',
                referrerPolicy: 'no-referrer'
            }).then(res => res.json()).then(response => {

                storage.destroy.authToken();
                storage.set.authToken(response.access_token);
                originalReq.headers['Authorization'] = `Bearer ${response.access_token}`;
                instance.defaults.headers.Authorization = `Bearer ${response.access_token}`;
                return axios(originalReq);
            })
            .catch(err => {
                storage.destroy.authToken();
                storage.destroy.user();
                storage.destroy.lastDateRequest();
                window.location.reload();
            });
            resolve(res);
        }

        return Promise.reject(error);
    });

});

export default instance;


