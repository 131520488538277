import React from 'react';
import './Load.scss'
import Spinner from "react-bootstrap/Spinner";

export default props => {
  return <div className="component-load">
    <Spinner animation="border" role="status">
      <span className="sr-only">Loading...</span>
    </Spinner>
    <br/>
    <h4 className="component-load__text">Carregando as informações...</h4>
  </div>;
}