const ACTIONS = {
    SEARCH_REQUESTS: 'SEARCH_REQUESTS',
    SEARCH_TOTAL_COLORS: 'SEARCH_TOTAL_COLORS',
    SEARCH_COPY_REQUESTS: 'SEARCH_COPY_REQUESTS',
    ALTER_LOADING: 'ALTER_LOADING',
    ALTER_COLOR_FILTER: 'ALTER_COLOR_FILTER',
    ALTER_BRANCH_FILTER: 'ALTER_BRANCH_FILTER',
    ALTER_ALERT: 'ALTER_ALERT',
    SALVE_NEW_ITEMS: 'SALVE_NEW_ITEMS',
}

const COLORS = {
    YELLOW: 'YELLOW',
    RED: 'RED',
    PINK: 'PINK',
    GREY: 'GREY',
};

export { ACTIONS, COLORS };