import React from "react";
import './SubBoard.scss'
import SubBoardDescription from "./subBoardDescription/SubBoardDescription";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons";
import Popover from 'react-bootstrap/Popover'

export default props => {
    return (
        <div className={"component-sub-board sub-board__" + props.class} key={props.item.idprescrip}>

            <div className="sub-board__box-one">
                <SubBoardDescription label="Prescrição" class={'id-prescription'} value={props.item.idprescrip}/>
                <SubBoardDescription label="Quant" class={'quant'} value={props.item.quantity}/>
            </div>
            <div className="sub-board__box-two">
                <SubBoardDescription label="Data da entrega" class={'required-date'} value={props.item.datarequerida}/>
                <SubBoardDescription label="Item" class={'generic-name'}
                                     value={props.item.scmaterial + " - (" + props.item.nomegenerico + ")"} />
                <SubBoardDescription label="Obs" class={'generic-name'}
                                     value={props.item.descricao } />                    
            </div>

            <div className="sub-board__box-new">
                {props.item.isNew ?

                    <span className={"badge badge-success"}>Novo</span>

                    : ''
                }
            </div>

            <div className="sub-board__box-tree">
                <OverlayTrigger
                    trigger={['hover', 'focus']}
                    placement={'left'}
                    overlay={
                        <Popover id={`popover-positioned-left`}>
                            <Popover.Title className={`sub-board__popover-${props.class}`}
                                           as="h3">{`Informação completa`}</Popover.Title>
                            <Popover.Content>
                                <SubBoardDescription label="ID" class={'id'} value={props.item.idadmission}/>
                                <SubBoardDescription label="Prescrição" class={'id-prescription'}
                                                     value={props.item.idprescrip}/>
                                <SubBoardDescription label="Item" class={'item'}
                                                     value={props.item.nomegenerico}/>
                                <SubBoardDescription label="Obs" class={'generic-name'}
                                     value={props.item.descricao } />                       
                                <SubBoardDescription label="Código do item" class={'code-item'}
                                                     value={props.item.scmaterial}/>
                                <SubBoardDescription label="Tipo" class={'type'} value={props.item.tipo}/>
                                <SubBoardDescription label="Quant" class={'quant'} value={props.item.quantity}/>
                                <SubBoardDescription label="Data inicial" class={'start-date'}
                                                     value={props.item.startdate}/>
                                <SubBoardDescription label="Data final" class={'end-date'}
                                                     value={props.item.enddate}/>
                                <SubBoardDescription label="Data da solicitação" class={'request-date'}
                                                     value={props.item.dt_solicitacao}/>
                                <SubBoardDescription label="Data da entrega" class={'required-date'}
                                                     value={props.item.datarequerida}/>
                                <SubBoardDescription label="Número da admissão" class={'id-admission'}
                                                     value={props.item.idadmission}/>
                                <SubBoardDescription label="Paciente" class={'patient'}
                                                     value={props.item.nomepaciente}/>
                                <SubBoardDescription label="Nome Genérico" class={'patient'}
                                                     value={props.item.nomegenerico}/>
                            </Popover.Content>
                        </Popover>
                    }
                >
                    <div className={`btn-exclamation__${props.item.color}`}>
                        <FontAwesomeIcon icon={faExclamationCircle}/>
                    </div>
                </OverlayTrigger>
            </div>
        </div>)
}