import {createStore} from "redux";
import {ACTIONS as __} from "../constants/constants";

const INITIAL_STATE = {
    requests: [],
    copyRequests: [],
    newItems: [],
    count: {
        yellow: 0,
        red: 0,
        pink: 0,
        grey: 0,
    },
    loading: false,
    colorFilter: 'CLEAR',
    branchFilter: 'ALL_FILIAL',
    alert: false
};

function reducers(state, {type, payload}) {
    switch (type) {
        case __.SEARCH_REQUESTS:
            return {...state, requests: payload}
        case __.SEARCH_COPY_REQUESTS:
            return {...state, copyRequests: payload}
        case __.SEARCH_TOTAL_COLORS:
            return {...state, count: payload}
        case __.ALTER_LOADING:
            return {...state, loading: payload}
        case __.ALTER_COLOR_FILTER:
            return {...state, colorFilter: payload}
        case __.ALTER_BRANCH_FILTER:
            return {...state, branchFilter: payload}
        case __.ALTER_ALERT:
            return {...state, alert: payload}
        case __.SALVE_NEW_ITEMS:
            return {...state, newItems: payload}
        default:
            return state;
    }
}

const stare = createStore(
    reducers,
    INITIAL_STATE,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default stare