import React from "react";
import {Route, Redirect} from "react-router-dom";
import isAuthenticated from "./isAuthenticated";

export default ({component: Component, ...rest}) =>
    <Route
        {...rest}
        render={props => isAuthenticated()
            ? <Redirect to={{pathname: '/', state: {from: props.location}}}/>
            : <Component {...props} />
        }
    />
