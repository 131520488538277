import React, {useState} from 'react';
import './InformationBoard.scss'
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import SubBoard from "./subBoard/SubBoard";
import {useSelector} from "react-redux";
import ItemCounter from "./itemCounter/ItemCounter";
import useCountItemsColorsByIdPrescription from "../../hooks/useCountItemsColorsByIdPrescription";
import NewItemCounter from "./newItemCounter/NewItemCounter";
import Alert from "react-bootstrap";

export default props => {
    const requests = useSelector(state => state.requests);
    const {countTotalColorsByIdPrescription} = useCountItemsColorsByIdPrescription();
    const [openAccordion, setOpenAccordion] = useState([]);

    return (
        <>
            <div className="component-information-board">
                <Accordion>
                    {requests ? requests.map((request, index) => {

                            return (
                                <Card className="reset-accordion" key={index}>
                                    <Accordion.Toggle
                                        as={Card.Header}
                                        className="reset-accordion"
                                        eventKey={index}
                                        onClick={event => request.totalNewItems = 0}
                                    >
                                        <div className="information-board">
                                            <div className="card-information-board">
                                                <div className={`card-information-board__button fa-icon-${request.color}`}>
                                                    <FontAwesomeIcon icon={faPlus}/>

                                                </div>
                                                <div className="card-information-board__prescription-id">
                                                <span
                                                    className={`information__label--${request.color}`}>{request.idprescrip}</span>
                                                </div>
                                                <div className="card-information-board__patient">
                                                    <span>{request.name}</span>
                                                </div>
                                                <div className="card-information-board__entry-date">
                                                    <span>{request.datarequerida}</span>
                                                </div>
                                                <div className="card-information-board__type">
                                                    <span
                                                        className={`reset-accordion__label  ${request.type.toUpperCase() === 'Implantação'.toUpperCase() ? 'reset-accordion__label--blue' : 'reset-accordion__label--grey' }`}
                                                    >{request.type}</span>
                                                </div>
                                                <div className="card-information-board__total-count">
                                                    <ItemCounter colors={countTotalColorsByIdPrescription(request.itens)}/>
                                                </div>
                                                {
                                                    request.totalNewItems > 0 ?
                                                        <div className="card-information-board__new">
                                                            <NewItemCounter total={request.totalNewItems}  />
                                                        </div> :
                                                        ''
                                                }
                                            </div>
                                        </div>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey={index}>
                                        <Card.Body>
                                            {request.itens.map((item, subIndex) =>
                                                <SubBoard key={subIndex} class={item.color} item={item}/>
                                            )}
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            );
                        }
                    ) : ''}
                </Accordion>
            </div>
        </>
    );
}

